<template>
  <v-container class="ma-0 pa-0">
    <v-row>
      <v-col cols="12">
        <v-card
          elevation="10"
          class="ma-1"
        >
          <v-toolbar
            dark
            dense
            color="orange"
          >
            <v-toolbar-title>{{ titulo }}</v-toolbar-title>
            <v-spacer />
            <v-btn
              class="ma-2"
              small
              color="deep-orange"
              @click="regresar"
            >
              <v-icon>mdi-reply</v-icon>
            </v-btn>

            <v-btn
              color="success"
              dark
              small
              @click="saveInfo"
            >
              <v-icon class="mr-2">
                mdi-check-circle
              </v-icon> PROCESAR
            </v-btn>
          </v-toolbar>

          <v-row>
            <v-col
              md="6"
              sm="6"
              xm="12"
              cols="12"
            >
              <v-card
                class="ma-2 pa-2"
                elevation="10"
              >
                <v-text-field
                  v-model="nomproy"
                  prepend-icon="mdi-pencil"
                  hide-details
                  label="Nombre del proyecto"
                />

                <!-- <v-text-field
                  v-model="numcli"
                  prepend-icon="mdi-account"
                  class="ma-3"
                  label="Cliente"
                /> -->

                <leecliente class="mb-2" />

                <v-card-title primary-title>
                  <v-toolbar
                    dark
                    dense
                    color="orange"
                  >
                    Líder del Proyecto
                  </v-toolbar>
    
                  <leecontacto />
                </v-card-title>
                
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    sm="5"
                    xm="12"
                  >
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="inicio"
                          class="ma-2"
                          label="Fecha Inicio"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="inicio"
                        locale="es"
                        @input="menu1 = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="5"
                    xm="12"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="meta"
                          class="ma-2"
                          label="Fecha Fin"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="meta"
                        locale="es"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
						
            <v-col
              cols="12"
              sm="6"
              md="6"
              xm="12"
            >
              <v-card
                class="ma-2 pa-2"
                elevation="10"
              >
                <v-textarea
                  v-model="obs"
                  hide-details
                  prepend-icon=""
                  height="90"
                  class="ma-3"
                  outlined
                  name="input-7-4"
                  label="Descripción detallada del proyecto"
                />

    						
                <v-text-field
                  v-model="precio"
                  class="ma-3"
                  outlined
                  type="number"
                  label="Monto estimado"
                />
    							
    							
                <v-list-item v-if="nModo == 2">
                  <template v-slot:default="{ }">
                    <v-list-item-action>
                      <v-checkbox v-model="completo" />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>PROYECTO TERMINADO</v-list-item-title>
                      <v-list-item-subtitle>Marca esta casilla si terminaste el proyecto</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'


export default{
  components:{
    leecontacto,
    leecliente
  },
  data(){
    return{
				
      detalle:'',
      titulo:'',
      inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      meta: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      fechaini: '',
      fechafin: '',
      nomproy:'',

      numcli:'',
      obs:'' ,
      precio: '',
      activo : true,
      completo: '0',

      //MODO
      nModo:'',
      modo:'',
      idproy :null,
      idproyecto:'',

      nomcont:'',
      cliente:{},
      contacto:{},
    }
  },

  computed:{
    ...mapGetters('contactos', ['getContacto', 'getNewContacto']),
    ...mapGetters('clientes', ['getCliente', 'getNewCliente']),


    validaInfo(){
      return  this.nomproy   !== ''  &&
        this.inicio !== '' &&
        this.obs !== ''
        // &&
        // this.numcli  !== ''
    },
	  },

  watch: {
    getContacto(){
      // this.limpiar()
      this.actualizarDatoscontactos()
    },

    getNewContacto(){
      this.actualizarNomcont()
    }
  },

  created(){

    // this.idcita = ''
    console.log('router', this.$route.params )
    // this.idcita = this.$route.params.idcita
    this.idproy = this.$route.params.proyecto

	    //MODO EDITAR
	    if ( this.idproy !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.cargarInfo(this.$route.params.proyecto)
	        this.modo = 'Editar'
      this.titulo = 'Editar proyecto'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = 'Nuevo proyecto'
	    }

  },


  beforeDestroy(){
    // console.log("SALIR beforeDestroy")
    this.setLimpiarContacto()

    //CLIENTES
    this.setLimpiarCliente()
  },



  methods:{
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions('clientes',['setCliente', 'setNewCliente','setLimpiarCliente']),
    ...mapActions('contactos',['setContacto','setLimpiarContacto']),
    

    cargarInfo(proyecto) {
      console.log("proyecto", proyecto)
      this.idproyecto = proyecto.idproyecto
      this.nomproy = proyecto.nomproy
      this.numcli = 	proyecto.numcli
      this.idcontacto = proyecto.idcontacto

      this.inicio =	proyecto.inicio
      this.meta =	proyecto.meta

      this.obs =	proyecto.obs
      this.precio =	proyecto.precio



      //CLIENTES

      if (this.numcli !== '') {
        let cNumcli = this.numcli.trim()
        this.$http.get("v2/clientebynumcli/"+ cNumcli ).then(response=>{
          console.log('response numcli',response.data)
          if (response.data.error === null){

            this.cliente = {
              numcli : response.data.result.numcli,
              nomcli : response.data.result.nomcli
            }

            this.setCliente(this.cliente)
          }
              
        }).catch(error=>{
          console.log(error)
        })
      }
     
      if (this.idcontacto !== 0) {
        // GET CONTACTO BY idcontacto.
        let cIdcontacto = this.idcontacto.toString()
        this.$http.get("v2/getcontacto/"+ cIdcontacto ).then(response=>{
          console.log('response idcontacto',response.data)
          if (response.data.error === null){

            this.contacto = {
              idcontacto : response.data.result.idcontacto,
              nomcont : response.data.result.nomcont
            }

            this.setContacto(this.contacto)
          }
              
        }).catch(error=>{
          console.log(error)
        })
 
      }
       


					
    },

    saveInfo(){
      console.log("saveInfo")
      if (!this.validaInfo){
	    	  this.showSnack({
	          text: "Debes registrar el nombre, cliente, detalle",
	          color: "orange",
	          timeout: 2000,
        	});
	     	  return
		    }

      var payload = {
        nomproy: this.nomproy,
        obs: this.obs,
        inicio: this.inicio,
        meta: this.meta,
        numcli: this.numcli,
        idcontacto : parseInt(this.idcontacto),
        idweb : this.idweb,
        precio: this.precio,
        completo: parseInt(this.completo)
      }

      console.log("payload", payload)
      // this.addCita(payload)



      //NUEVO
      if (this.nModo == 1) {
				   //Dedinir db name
        this.$http.post('v2/insert.proyecto', payload).then( response =>{
		        console.log("response" ,response)

		        this.showSnack({
	            text: "Proyecto agregada correctamente",
	            color: "success",
	            timeout: 2000,
	          });

		        this.limpiarProyecto()
          setTimeout (() => this.$router.push({name:'catproyectos'}),2000);
        }).catch(error=>{
          	// this.snackbar = true ;
          // this.color="error"
          // this.text = "Problemas para grabar el cliente"
          console.log(error)
       			this.showSnack({
	            text: "Problema para grabar proyecto"+ error,
	            color: "error",
	            timeout: 2000,
	          });
   
        })
      }

      //EDITAR
      if ( this.nModo == 2 ){

        payload.idproyecto= this.idproyecto
				
        this.$http.put('v2/update.proyecto/' + this.idproyecto, payload).then(response =>{
          this.showSnack({
	            text: "Proyecto grabado correctamente	",
	            color: "success",
	            timeout: 2000,
	          });
          setTimeout (() => this.$router.push({name:'catproyectos'}),2000);

        }).catch(error=>{
          	this.showSnack({
	            text: "Problema para grabar proyecto."+ error.error,
	            color: "error",
	            timeout: 2000,
	          });
          console.log(error)
        })
      }

    },

    regresar(){
				 this.$router.push({name:'catproyectos'})
    },

    limpiarProyecto(){

      this.nomproy = ''
      this.obs = ''
      this.inicio =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

      this.meta= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
       
      this.monto = 0.00

      this.nomcont = ''
      this.numcli = ''
      // numcli: this.numcli,
      // precio: this.precio,
      // completo: this.completo

    },

    actualizarDatoscontactos() {
      this.idcontacto = this.getContacto.idcontacto
      this.nomcont = this.getContacto.nomcont
    },

    actualizarNomcont(){
      this.nomcont = this.getNewContacto.nomcont
    },


  }
			


}
</script>